import "./App.css";
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Typography, Button, Input, Row, Col } from "antd";
import { Dna } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { SafetyOutlined } from "@ant-design/icons";
import axios from "./api/axios";
import SelectTopic from "./components/SelectTopic";
import MCQ from "./components/Mcq";
import { Navigation } from "./components/Navigation";
const { Title } = Typography;
const HEADING = {
    topic: "Topic",
    story: "Clinical vignette/scenario",
    variables: "List of variables in selected theme",
    questionCategories: "Question Categories",
    question: "Questions",
    Categories: "Question Categories",
    storyCategories: "Question Topic",
    Correct: "Correct Options",
    Correct1: "Correct Options",
    options: "Other Options",
    optionSection: "Distractor Options",
    previewButton: "Generate Items",
    generateButton: "Download TeX File",
    addQuestionCategory: "Add Question Category",
    addQuestion: "Add Question",
};
function AIGFive() {
    const [topic, setTopic] = useState("");
    const [topicData, setTopicData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [noOfMcqs, setNoOfMcqs] = useState(0);
    const [mcqItems, setMcqItems] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [possibleCombinations, setPossibleCombinations] = useState(0);
    const [showTopic, setShowTopic] = useState(false);
    const [showMcqLoader, setShowMcqLoader] = useState(false);
    const [casesList, setCasesList] = useState([])
    const [casesWiseCategories, setCasesWiseCategories] = useState([])
    const [casesPossibleCombinations, setCasesPossibleCombinations] = useState([])
    useEffect(() => {
        axios
            .get("fetch_topic/", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${window.localStorage.getItem("access")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                const Data = res?.data?.topics?.map((item) => ({
                    label: item.name,
                    value: item.name,
                }));
                setTopicData(Data);
                setShowTopic(true)
            })
            .catch((err) => {
                setShowTopic(true);
            });
    }, []);
    useEffect(() => {
        axios
            .get("fetch_categories/", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${window.localStorage.getItem("access")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                const Data = res?.data?.map((item) => ({
                    label: item.name,
                    selected: false,
                }));
                setCategoryData(Data);
            })
            .catch((err) => { });
    }, []);
    useEffect(() => {
        setPossibleCombinations(0);
        setNoOfMcqs(0);
        let categories = [];
        for (let i = 0; i < categoryData.length; i++) {
            if (categoryData[i]?.selected === true) {
                categories.push(categoryData[i]?.label);
            }
        }
        if (categories.length > 0) {
            setShowMcqLoader(true);
            axios
                .post("checksentence/", {
                    topic,
                    categories,
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${window.localStorage.getItem("access")}`,
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    setShowMcqLoader(false);
                    setPossibleCombinations(res.data.all_combinations);
                    setCasesList(res.data.cases_list)
                    setCasesWiseCategories(res.data.cases_category_list)
                    setCasesPossibleCombinations(res.data.cases_possible_combinations);
                    if (
                        res?.data?.all_combinations === 0
                    ) {
                        alert(
                            "Sorry! We didn't find any case that has the codes fits with any story"
                        );
                        setNoOfMcqs(0);
                    }
                })
                .catch((err) => {
                    setShowMcqLoader(false);
                });
        }
    }, [categoryData, topic]);
    function onSubmitStories(e) {
        e.preventDefault();
        generate()
    }
    function shuffleArray(array) {
        const shuffledArray = array.slice();
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    }
    function generate() {
        setMcqItems([])
        let categories = [];
        for (let i = 0; i < categoryData.length; i++) {
            if (categoryData[i]?.selected === true) {
                categories.push(categoryData[i]?.label);
            }
        }
        if (noOfMcqs && categories.length > 0) {
            setShowLoader(true);
            axios
                .post("FetchMcqFromCases/", {
                    topic,
                    noOfMcqs,
                    categories,
                    casesList,
                    casesWiseCategories,
                    casesPossibleCombinations
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${window.localStorage.getItem("access")}`,
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    const mcqs = res?.data;
                    let items = [];

                    for (let i = 0; i < mcqs?.length - 1; i++) {
                        let optionsArray = mcqs[i]?.distract_options;
                        optionsArray.push(`${mcqs[i]?.correct_option} *`);
                        const shuffleOptionsArray = shuffleArray(optionsArray)
                        let mcqBody = {
                            story: mcqs[i]?.story,
                            question: mcqs[i]?.question,
                            category: mcqs[i]?.category,
                            corectOption: mcqs[i]?.correct_option,
                            caseName: mcqs[i]?.case_name,
                            alternativeOptions: mcqs[i]?.distract_options,
                            options: shuffleOptionsArray
                        };
                        items.push(mcqBody);
                    }
                    if (items?.length) {
                        setShowLoader(false);
                        setMcqItems(items);
                    } else {
                        alert(
                            `Sorry! We didn't find any case that fits to codes used in your story.`
                        );
                    }
                })
                .catch((err) => {
                    alert('Server Error')
                    setShowLoader(false);
                });
        } else {
            alert("choose a category first");
        }
    }
    const updateFieldChanged = (index) => {
        let newArr = [...categoryData];
        newArr[index].selected = !newArr[index].selected;
        setCategoryData(newArr);
    };
    function getAllStories(storyParameter, setData, variables, gender_list) {
        const allStories = [];
        for (let i = 0; i < setData.length; i++) {
            let s = storyParameter;
            let ageIndex;
            let genderIndex;
            if (s?.includes("(1)") && s?.includes("(2)")) {
                ageIndex = variables?.indexOf(1);
                genderIndex = variables?.indexOf(2);
            }
            for (let j = 0; j < setData[i].length; j++) {
                if (
                    typeof ageIndex !== "undefined" &&
                    typeof genderIndex !== "undefined"
                ) {
                    if (typeof ageIndex !== "undefined" && j === ageIndex) {
                        s = s.replace("(" + variables[j] + ")", setData[i][j] + "-" + gender_list[i].toLowerCase() + " old");
                    }
                    if (gender_list[i] === "YEARS") {
                        if (setData[i][ageIndex] > 19 && j === genderIndex) {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "man"
                                    : setData[i][j] === "female"
                                        ? "woman"
                                        : setData[i][j]
                            );
                        } else {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "boy"
                                    : setData[i][j] === "female"
                                        ? "girl"
                                        : setData[i][j]
                            );
                        }
                    } else if (gender_list[i] === "MONTHS") {
                        if (setData[i][ageIndex] > 216 && j === genderIndex) {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "man"
                                    : setData[i][j] === "female"
                                        ? "woman"
                                        : setData[i][j]
                            );
                        } else {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "boy"
                                    : setData[i][j] === "female"
                                        ? "girl"
                                        : setData[i][j]
                            );
                        }
                    } else if (gender_list[i] === "WEEKS") {
                        if (setData[i][ageIndex] < 5 && j === genderIndex) {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "noenate"
                                    : setData[i][j] === "female"
                                        ? "noenate"
                                        : setData[i][j]
                            );
                        } else if (setData[i][ageIndex] > 940 && j === genderIndex) {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "man"
                                    : setData[i][j] === "female"
                                        ? "woman"
                                        : setData[i][j]
                            );
                        } else {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "boy"
                                    : setData[i][j] === "female"
                                        ? "girl"
                                        : setData[i][j]
                            );
                        }
                    } else if (gender_list[i] === "DAYS") {
                        if (setData[i][ageIndex] < 29 && j === genderIndex) {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "noenate"
                                    : setData[i][j] === "female"
                                        ? "noenate"
                                        : setData[i][j]
                            );
                        }
                        else if (setData[i][ageIndex] > 6570 && j === genderIndex) {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "man"
                                    : setData[i][j] === "female"
                                        ? "woman"
                                        : setData[i][j]
                            );
                        } else {
                            s = s.replace(
                                "(" + variables[j] + ")",
                                setData[i][j] === "male"
                                    ? "boy"
                                    : setData[i][j] === "female"
                                        ? "girl"
                                        : setData[i][j]
                            );
                        }
                    }
                }
                else {
                    s = s.replace("(" + variables[j] + ")", setData[i][j]);
                }
            }
            allStories.push(s);
        }
        return allStories;
    }
    return (
        <>
            <main className="App">
                <Header name="Automatic Item Generator v.5 (AIG-5)" className="mb-5" />
                <Navigation navType={"home"} />
                {!showTopic ? (
                    <div className="text-center" style={{ marginLeft: "15rem" }}><Dna
                        visible={true}
                        height="60"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                        style={{ color: "white" }}
                    /></div>
                ) : (
                    <div className="main-bd" style={{ marginTop: "3rem" }}>
                        <SelectTopic
                            topic={topic}
                            setTopic={setTopic}
                            topicData={topicData}
                        />
                        {/* </div> */}
                        {topic !== "" && (
                            <div className="my-5">
                                <Title level={3} className="mb-3">
                                    {HEADING.questionCategories}:
                                </Title>
                                <div className="" style={{ display: "flex", flexWrap: "wrap" }}>
                                    {categoryData?.map((elem, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="mb-2 col-xl-3 col-lg-4 col-md-4 col-12"
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                <div style={{ display: "inline-block" }}>
                                                    <Form.Check
                                                        aria-label={`category ${index}`}
                                                        checked={elem?.selected}
                                                        onChange={() => updateFieldChanged(index)}
                                                    />
                                                </div>
                                                <div style={{ display: "inline-block" }}>
                                                    &nbsp;{elem?.label}&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {showMcqLoader && topic ? (
                    <center>
                        <Dna
                            visible={true}
                            height="60"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                            style={{ color: "white" }}
                        />
                    </center>
                ) : (
                    <>
                        {categoryData?.filter((elem) => elem.selected === true).length >
                            0 && possibleCombinations !== 0 && (
                                <>
                                    <div>
                                        <Row
                                            style={{
                                                verticalAalign: "middle",
                                                marginBottom: "35px",
                                            }}
                                        >
                                            <Col>
                                                <h4 style={{ marginTop: "5px" }}>
                                                    Enter No of MCQs To Generate: &nbsp;{" "}
                                                </h4>
                                            </Col>
                                            <form onSubmit={(e) => onSubmitStories(e)}>
                                                <Col className="d-flex">
                                                    <Input
                                                        style={{
                                                            height: "40px",
                                                            width: "100px",
                                                            borderRadius: "0.5rem",
                                                        }}
                                                        placeholder={1 + " - " + possibleCombinations}
                                                        type="number"
                                                        min={1}
                                                        max={possibleCombinations}
                                                        name={noOfMcqs}
                                                        value={noOfMcqs !== 0 && noOfMcqs}
                                                        onChange={(e) =>
                                                            setNoOfMcqs(e.target.value)
                                                        }
                                                    />
                                                    &nbsp;
                                                    <Button
                                                        size="large" //TODO
                                                        icon={<SafetyOutlined />}
                                                        disabled={showLoader}
                                                        onClick={(e) => onSubmitStories(e)}
                                                        className="d-flex align-items-center "
                                                    >
                                                        <>{HEADING.previewButton} (Questions)</>
                                                    </Button>
                                                    {showLoader && (
                                                        <Dna
                                                            visible={true}
                                                            height="50"
                                                            width="80"
                                                            ariaLabel="dna-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass="dna-wrapper"
                                                            style={{ color: "white" }}
                                                        />
                                                    )}

                                                </Col>
                                            </form>
                                        </Row>
                                    </div>
                                    {mcqItems.length > 0 && <MCQ MCQsItems={mcqItems} topic={topic} />}
                                </>
                            )}
                    </>
                )}
            </main>
        </>
    );
}

export default AIGFive;
